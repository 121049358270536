<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div v-if="message" class="alert alert-danger mb-10" role="alert">
            {{ message }}
          </div>
          <Form
            @submit="handleSubmit"
            :validation-schema="schema"
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
          >
            <div class="mb-5">
              <Field
                type="text"
                placeholder="name"
                name="name"
                class="form-control bg-transparent"
                v-model="formInput.name"
              />
              <ErrorMessage
                name="username"
                class="text-danger error-feedback"
              />
            </div>
            <div>
              <button
                class="btn btn-secondary"
                @click="$router.push({ name: 'permission-list' })"
              >
                Back
              </button>
              <button
                :disabled="loading"
                type="submit"
                id="kt_sign_in_submit"
                class="btn btn-success"
              >
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress" v-show="loading">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  >
                  </span>
                </span>
              </button>
            </div>
          </Form>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Service from "../../../services/base.service";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required("Name is required!"),
    });

    return {
      loading: false,
      message: "",
      formInput: { name: "" },
      schema,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
    // this.getListData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const BaseService = new Service("permission");
        const { data } = await BaseService.getData(this.$route.params.id);
        this.formInput = data;
        // this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(data) {
      this.loading = true;
      const BaseService = new Service("permission");
      try {
        const { message } = this.$route.params.id
          ? await BaseService.updateData(this.$route.params.id, data)
          : await BaseService.createData(data);
        this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
